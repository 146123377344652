import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Link } from 'gatsby'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useAppState } from '../../appState'
import axios from '../../axios'
import { ENDPOINTS, FONTS, ROUTES } from '../../constants'
import chirpyestLogo from './../../assets/images/chirpyest-logo.svg'
import './carousel.css'
import { styles } from './styles'
import 'lazysizes'

interface BannerProps {
  t: TFunction
}

const Banner = ({ t }: BannerProps) => {
  const [appState] = useAppState()
  const [isCopied, setIsCopied] = useState(false)
  const classes = styles()
  const [allBanner, setAllBanner] = useState([])
  const [bannerToShow, setBannerToShow] = useState([])
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const userLoggedIn = appState.userId > 0
  const username = appState.username
  const userJoinLink = `${new URL(process.env.GATSBY_SITE_URL).host.replace(
    'www.',
    ''
  )}/join/${username}-${appState.userId}`
  const isMobileDevice = /android|iphone|kindle|ipad/i.test(navigator.userAgent) //eslint-disable-line
  useEffect(() => {
    getBanner()
  }, [])

  useEffect(() => {
    checkScreen(allBanner)
  }, [isPhone, isTablet, userLoggedIn, allBanner, username])

  const getBanner = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(ENDPOINTS.banner)
      setAllBanner(data)
    } catch (err) {
      setAllBanner([])
    }
  }

  const checkScreen = (banner: any[]) => {
    const sortedData: any = []
    const joinLinkBannerIndex = banner.findIndex(b =>
      b.content.includes('{{joinLinkBanner}}')
    )
    const copiedBanner =
      joinLinkBannerIndex === -1
        ? [...banner]
        : userLoggedIn && username !== null
        ? banner.map((b, i) =>
            i === joinLinkBannerIndex
              ? {
                  id: 'joinLinkBanner',
                }
              : b
          )
        : banner.filter((b, i) => i !== joinLinkBannerIndex)

    if (isPhone || isTablet) {
      copiedBanner.forEach(item => {
        if (item?.id !== 'joinLinkBanner') {
          sortedData.push([item])
        }
      })
      setBannerToShow(sortedData)
    } else {
      copiedBanner.forEach((item, index) => {
        if (index % 3 === 0) {
          sortedData.push([item])
        } else {
          sortedData[Math.floor(index / 3)].push(item)
        }
      })
      setBannerToShow(sortedData)
    }
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${process.env.GATSBY_SITE_URL}/join/${username}-${appState.userId}`
    )
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 5000)
  }

  return (
    <div className={classes.sloganBannerWrapper}>
      <Carousel
        showStatus={false}
        autoPlay
        stopOnHover
        infiniteLoop
        showArrows={!isMobileDevice}
        showThumbs={false}
        showIndicators={bannerToShow.length > 1 && !isMobileDevice}
      >
        {bannerToShow.map((items: any, index: any) => (
          <div key={index} className={classes.bannerItems}>
            {items.map(({ content, id }: any, index: number) => (
              <div
                key={id}
                className={`${classes.bannerItem} ${userLoggedIn &&
                  classes.fullWidth}`}
              >
                {id === 'joinLinkBanner' ? (
                  <>
                    <Typography
                      className={classes.sloganTitle}
                      style={{
                        fontSize: '0.875rem',
                        fontFamily: FONTS.Graphik.GraphikLight,
                      }}
                    >
                      <p>
                        refer a friend,{' '}
                        {appState?.userProfile?.isReferralBonus
                          ? 'get $10!'
                          : ''}{' '}
                        use join link <span> </span>
                        <span
                          className={classes.joinLinkSpan}
                          onClick={handleCopyLink}
                        >
                          {userJoinLink}
                        </span>
                      </p>
                      {isCopied && (
                        <Typography
                          variant="caption"
                          className={classes.copiedCaption}
                        >
                          code copied!
                        </Typography>
                      )}
                    </Typography>
                    {!isPhone && !isTablet && (
                      <span className={classes.bannerBreak}>|</span>
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.sloganTitle}
                      dangerouslySetInnerHTML={{ __html: content }}
                      style={{
                        fontSize: '0.875rem',
                        fontFamily: FONTS.Graphik.GraphikLight,
                      }}
                    ></Typography>
                    {index !== items.length - 1 ? (
                      <span className={classes.bannerBreak}>|</span>
                    ) : null}
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
      </Carousel>
      <Link
        rel="preconnect"
        to={userLoggedIn ? ROUTES.member : ROUTES.home}
        className={`${classes.chirpyestLogoLink} ${classes.listItem}`}
      >
        <img
          src={chirpyestLogo}
          className={classes.chirpyestLogo + ' lazyload '}
          alt="chirpyest-logo"
        />
      </Link>
    </div>
  )
}

export default withTranslation()(Banner)
